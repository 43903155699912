<script lang="ts" setup>
const isAuthenticated = useAuthState();
const openMobileNavigation = ref(false);

function toggleMobileNavigation(event: Event) {
  event.stopPropagation();
  openMobileNavigation.value = !openMobileNavigation.value;
}

function closeMobileNavigation() {
  openMobileNavigation.value = false;
}

function handleClickOutside(event: MouseEvent) {
  const menu = document.getElementById('tw-navbar');
  if (menu && !menu.contains(event.target as Node)) {
    closeMobileNavigation();
  }
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside);
});

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside);
});
</script>

<template>
  <div class="tw-header">
    <nuxt-link
      id="page_logo"
      class="tw-page-logo"
      :to="isAuthenticated ? '/dashboard' : '' + '/login'"
      :title="$t('header.go_to_startpage')"
      :aria-label="$t('header.go_to_startpage')"
    >
      <tw-icon-logo variant="black" :with-claim="true" :width="212" :height="33" class="tw-header-logo tw-page-logo-img" />
      <tw-icon-logo variant="blue" :with-claim="true" :width="244" :height="30" class="tw-header-logo tw-page-logo-img tw-page-logo-img--desktop" />
    </nuxt-link>

    <tw-ui-activate-subscription />

    <tw-layout-page-navigation-toggle
      v-if="!isAuthenticated"
      @click="toggleMobileNavigation"
      @keydown.prevent.space.enter="toggleMobileNavigation"
      class="tw-header-navigation-toggle"
      data-toggle="collapse"
      data-target="#tw-navbar"
      aria-label="toggle-navigation-button"
    />
    <tw-layout-page-navigation
      id="tw-navbar"
      :class="['tw-header-navigation', { 'tw-header-navigation--show': openMobileNavigation }]"
      @item-clicked="closeMobileNavigation"
    />
  </div>
</template>

<style lang="scss" scoped>
@import '~/assets/styles/tw-variables.scss';
@import '~/assets/styles/tw-mixins.scss';

.tw-page-logo {
  display: flex;
  align-items: center;

  &-img {
    display: inline-block;

    &--desktop {
      display: none;
    }
  }
}

.tw-header {
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto 1fr;
  align-items: center;
  z-index: 20;
  position: relative;
  background-color: $white;
  padding: 0.5rem 1rem;

  & > * {
    vertical-align: middle;
  }

  &-navigation {
    display: none;
    z-index: 15;

    &-toggle {
      margin-left: auto;
      width: auto;
    }

    &--show {
      display: block;
      grid-column: 1 / span 2;
    }
  }
}

.tw-header > .activate-subscription {
  grid-column: 2;
  justify-self: end;
  @include tw-is-desktop {
    display: none;
  }
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid $lightGray;
  margin: 1em 0;
  padding: 0;
}

.tw-page-logo-img {
  height: 33px;
}

@include tw-is-desktop {
  .tw-header {
    &-navigation {
      display: block;
      grid-column: 2;

      &-toggle {
        display: none;
      }
    }
  }

  .tw-page-logo {
    &-img {
      display: none;

      &--desktop {
        height: 30px;
        display: inline-block;
      }
    }
  }
}
</style>
